import React from "react"
import Layout from "../components/layout"

function trim(str, ch) {
  let start = 0,
    end = str.length
  while (start < end && str[start] === ch)
    ++start
  while (end > start && str[end - 1] === ch)
    --end
  return (start > 0 || end < str.length) ? str.substring(start, end) : str
}

const Post = ({ pageContext }) => {
  const title = pageContext.content.text
  let date = pageContext.content.date.split("T")
  date = date[0] + " " + date[1].split(":")[0] + ":" + date[1].split(":")[1]
  let texts = pageContext.content.long_text ? pageContext.content.long_text.split("\n") : []
  const paragraphs = []

  // Remove title from all augmented texts
  texts = texts.map((text) => {
    text = text.replace(title, "")
    text = trim(text, "-")
    text = trim(text, ":")
    text = trim(text, " ")
    return text
  })

  // Filter empty texts
  texts = texts.filter((text) => {
    return text !== ""
  })

  // Add fullstops where appropriate
  texts = texts.map((text) => {
    if (!text.endsWith(".")) {
      text += "."
    }
    return text
  })

  // Merge augmented texts
  let paragraph = ""
  for (let i = 0; i < texts.length; i++) {
    paragraph += texts[i] + " "
    if (0 === i % 7) {
      // TODO: Need a more sophisticated trimming
      paragraphs.push(paragraph)
      paragraph = ""
    }
  }

  return (
    <Layout menuList={[pageContext.lang, pageContext.yearDate, pageContext.monthDate, pageContext.dayDate]}
            pageTitle={pageContext.content.text} pageLang={pageContext.lang}>
      <div style={{ marginTop: "30px" }} className="container">
        <div>
          <h1>{title}</h1>
        </div>
        <div style={{ marginTop: "20px" }} className="col-md-12">
          <b>Date:</b> {date}
        </div>
        {paragraphs.map((value, index) => {
          return <p key={index}>{value}</p>
        })}
        <div style={{ marginTop: "20px" }} className="col-md-12">
          <b>Original URL:</b> <a style={{ lineBreak: "anywhere" }} target={"_blank"} href={pageContext.url}
                                  rel="nofollow noopener noreferrer">{pageContext.url}</a>
        </div>
      </div>
    </Layout>
  )
}

export default Post
